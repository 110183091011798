<template>
  <div>
    <PageHeader>
      <!-- <template slot="right">
        <b-button-group>
          <b-button
            :variant="filter === 'today'?'dark':'light'"
            @click="filter = 'today'"
          >
            Today
          </b-button>
          <b-button
            :variant="filter === 'all'?'dark':'light'"
            @click="filter = 'all'"
          >
            All
          </b-button>
        </b-button-group>
      </template> -->
    </PageHeader>
    <b-card>
      <b-row>
        <b-col>
          <h6>
            {{ `${$t('fields.result')} (${transactions.length})`}}
          </h6>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="transactions"
        :fields="fields"
        :busy="game.isFetchingBotCredits || game.isFetchingBotCreditsToday"
        responsive
        show-empty
      >

        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(maxLose)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(maxWin)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(creditBegin)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(creditBalance)="data">
          {{ data.value | currency }}
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
        </template>
      </b-table>
      <!-- pagination -->
      <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Bot - Game Credit Transactions',
  },
  data() {
    return {
      filter: 'all',
      currentPage: 1,
      limit: 50,
      fields: [
        {
          key: 'createdAt',
          label: this.$t('fields.date'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'totalGame',
          label: this.$t('games.all_games'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'totalWin',
          label: this.$t('games.all_win'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'totalLose',
          label: this.$t('games.all_loss'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'maxLose',
          label: 'เสียเงินได้ต่ำสุด',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'maxWin',
          label: 'ชนะได้มากสุด',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'creditBegin',
          label: 'เงินเริ่มต้น',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'creditBalance',
          label: 'เงินรวม',
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState(['game']),
    ...mapGetters(['botCredits', 'botCreditsToday']),
    transactions() {
      return this.botCredits.items || []
    },
    totalRow() {
      return this.botCredits.totalCount || 0
    },
  },
  watch: {
    currentPage(val) {
      this.fetchData(val)
    },
    filter(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchBotCredits', 'fetchBotCreditsToday']),
    fetchData(page) {
      if (this.filter === 'all') {
        this.fetchBotCredits({
          limit: this.limit,
          page,
        })
      } else {
        this.fetchBotCreditsToday()
      }
    },
  },
}
</script>